import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
import { alreadyRead, futureReading } from "../assets/reading";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ReadingList = makeShortcode("ReadingList");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This is a list of selected articles and talks I’ve enjoyed and that which I aim to read or watch, listed in alphabetical order.`}</p>
    <h3 {...{
      "id": "already-read",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#already-read",
        "aria-label": "already read permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`already read`}</h3>
    <ReadingList list={alreadyRead} mdxType="ReadingList" />
    <h3 {...{
      "id": "future-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#future-reading",
        "aria-label": "future reading permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`future reading`}</h3>
    <ReadingList list={futureReading} mdxType="ReadingList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      